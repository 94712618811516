<template>
  <div v-if="$isLoading">
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col xl="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายการเงื่อนไขขนส่งต่างประเทศ
          </h1>
        </b-col>
        <!-- <b-col xl="8" class="text-right">
          <div class="d-flex justify-content-end">
            <b-button variant="main" @click="addNewConditon()"
              >เพิ่มเงื่อนไข</b-button
            >
          </div>
        </b-col> -->
      </CRow>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(enabled)="data">
                <div>
                  <b-form-checkbox
                    switch
                    class="radio-active ml-3"
                    size="lg"
                    v-model="data.item.enabled"
                    :disabled="true"
                  >
                    <span class="ml-2 main-label">
                      {{ data.item.enabled ? "เปิด" : "ปิด" }}
                    </span>
                  </b-form-checkbox>
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="text-center">
                  <router-link
                    :to="'/shipping-setting/abroad/details/' + data.item.id"
                  >
                    <b-button variant="link" class="text-dark px-1 py-0">
                      ตรวจสอบ
                    </b-button>
                  </router-link>
                  <!-- <b-button
                    variant="link"
                    @click="deleteData(data.item.id)"
                    class="text-dark px-1 py-0"
                  >
                    ลบ
                  </b-button> -->
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>
            <b-form-select
              class="mr-sm-3 select-page"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";

export default {
  name: "SettingDetails",
  components: {
    InputText,
    ModalAlertError,
    ModalAlertConfirm,
    ModalLoading,
    ModalAlert,
    InputSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      modalMessage: "",
      fields: [
        // {
        //   key: "id",
        //   label: "",
        //   class: "w-25x",
        // },
        {
          key: "shippingTypeName",
          label: "ชื่อขนส่ง",
          class: "w-100px",
        },

        {
          key: "shippingConditionName",
          label: "ประเภทเงื่อนไข",
          class: "w-100px",
        },
        {
          key: "enabled",
          label: "สถานะ",
          class: "w-100px",
        },
        {
          key: "action",
          label: "",
          class: "w-100px",
        },
      ],
      isBusy: false,
      error: [],
      condition: 0,
      enabled: true,
      form: {},
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Type: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
    };
  },
  created: async function () {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let res = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Shipping/AboardShipping`,
        null,
        this.$headers,
        this.filter
      );
      if (res.result == 1) {
        this.items = res.detail;
      }
      this.isBusy = false;
    },
    pagination(Page) {
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
  },
};
</script>

<style scoped>
::v-deep .table td {
  vertical-align: baseline !important;
}

::v-deep .table-responsive {
  /* height: 320px; */
  overflow: auto;
}

.input-serach.error {
  border: 1px solid #e55353 !important;
}

.text-error,
.text-danger {
  color: #ff0000 !important;
  font-size: 14px;
}

.panel-input-serach {
  border: 1px solid #bcbcbc !important;
}

.search-modal {
  border: 2px solid #d0d0d0 !important;
}

.border-danger {
  border: 1px solid #e55353 !important;
}

label {
  color: #16274a;
  font-size: 17px;
  margin-bottom: 2px;
  width: 100%;
  font-weight: bold;
}

::v-deep .square-boxs {
  margin: auto;
  width: 75%;
  padding-top: 60%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep .dropdown-menu-right {
  width: 200px;
  padding: 5px;
}

::v-deep .modal-body .table-detail {
  min-height: 40rem;
}

.modal-header {
  border: 0 !important;
  padding: 0;
}

.text-text {
  color: #16274a;
  margin-bottom: 0;
  font-size: 26px;
}

.img {
  width: 100px;
}

.modal-body {
  margin: auto;
  text-align: center;
}

.category-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  padding: 5px 10px;
  max-width: 100%;
  text-align: left;
  cursor: pointer;
  border: 1px solid #bcbcbc !important;
}

.category-popover {
  position: absolute;
  z-index: 100;
  border: 1px solid #dcdcdc;
  overflow-y: auto;
  right: 0;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12),
    0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

@media (max-width: 820px) {
  ::v-deep .modal-dialog {
    max-width: 700px !important;
  }
}

@media (max-width: 600px) {
  .category-main-box {
    width: 100%;
    margin-top: 10px;
  }

  .category-popover {
    right: 15px;
  }
}
</style>
